<template>
  <div class="container">
    <FormContainer v-if="success !== true" class="row" @submitForm="changePassword">
      <FormInputTextWidget
        class="col-12"
        label="Password"
        v-model="password"
        :errorMsg="errorHash.password"
        type="password"
        @input="removeError('password')"
      />
      <FormInputTextWidget
        class="col-12"
        label="Conferma Password"
        v-model="confirmPassword"
        :errorMsg="errorHash.confirmPassword"
        type="password"
        @input="removeError('confirmPassword')"
      />
      <div class="col-12 mt-4">
        <button class="btn btn-primary" type="submit">Reset</button>
      </div>
      <div v-if="success === false" class="col-12 mt-2">
        <FormErrors>
          Si è verificato un errore non previsto, si prega di riprovare
        </FormErrors>
      </div>
    </FormContainer>
    <div v-else>
      <p class="h4">Password modificata con successo</p>
      <p class="h6">Se non vieni reindirizzato al Login entro breve clicca <router-link :to="{ name: 'login' }">qui</router-link></p>
    </div>
  </div>
</template>

<script>
import validateMixin from '../../../libs/Form/mixins/validate';

export default {
  name: 'password-change',
  data () {
    return {
      password: null,
      confirmPassword: null,
      success: null,
    };
  },
  components: {
    FormInputTextWidget: () => import('../../../libs/Form/components/Inputs/Widgets/FormInputTextWidget'),
    FormContainer: () => import('../../../libs/Form/components/FormContainer'),
    FormErrors: () => import('../../../libs/Form/components/FormErrors'),
  },
  mixins: [validateMixin],
  methods: {
    showFinalMessage () {
      this.success = true;
      setTimeout(() => {
        this.$router.replace({ name: 'login' });
      }, 2000);
    },
    notifyError () {
      this.success = false;
    },
    changePassword () {
      if (!this.isValid()) {
        return;
      }

      this.$api.changePasswordWithToken(
        this.$route.params.email,
        this.$route.params.token,
        this.password,
      ).then(this.showFinalMessage, this.notifyError).catch(this.notifyError);
    },
    isValid () {
      if (!this.password) {
        this.addError('Password mancante', 'password');
      } else if (this.password.length < 8 || !this.password.match(/\d|[^\w\s]/)) {
        this.addError('La password deve avere almeno 8 caratteri, compreso un numero o un simbolo.', 'password');
      } else if (!this.confirmPassword) {
        this.addError('Conferma password mancante', 'confirmPassword');
      } else if (this.password !== this.confirmPassword) {
        this.addError('La conferma password deve essere uguale alla password', 'confirmPassword');
      }
      return !this.hasErrors;
    },
  },
};

</script>

<style lang="scss"></style>
